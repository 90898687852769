import { getServerUrl } from './functions';
import { IEnvironment } from './interfaces';
import { sharedConfig } from './shared';

const gamesServerDomain = 'games-server.bethehouse.com';
const gamesServerPort = 443;
const gamesServerHost = gamesServerDomain + ':' + gamesServerPort;

const externalGamesServer = {
  host: 'ss-test.bethehouse.com',
  port: 444,
  ssl: true,
};

const externalGamesServerHost =
  externalGamesServer.host + ':' + externalGamesServer.port;

const tradingServer = {
  host: 'trading-server.bethehouse.com',
  port: 443,
  ssl: true,
};

export const stagingEnvironment: IEnvironment = {
  ...sharedConfig,

  production: true,

  apiUrl: 'https://' + gamesServerHost,

  gamesSocket: {
    host: gamesServerDomain,
    port: gamesServerPort,
    ssl: true,
  },

  externalGamesSocket: externalGamesServer,
  externalGamesApi:
    (externalGamesServer.ssl ? 'https://' : 'http://') +
    externalGamesServerHost,

  currencyApiUrl: 'https://currency-server.bethehouse.com',

  chatServer: {
    host: 'chat.bethehouse.com',
    port: 443,
    ssl: true,
  },
  chatServerApiUrl: 'https://chat.bethehouse.com',

  recaptchaSiteKey: '6Lc7I_0cAAAAABkBroiSSqOMi1CkL2Gbs0EfLwgX',

  tradingServer,
  tradingServerApiUrl: getServerUrl(tradingServer),
};
