import { IEosConfig } from './interfaces';

const eosConfig: IEosConfig = {
  /*** WAX MAINNET: ***/
  chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
  protocol: 'https',
  host: 'wax.greymass.com',
  port: 443,
  /***/
};

export const sharedConfig = {
  eosConfig,
};
