export function getServerUrl({
  host,
  port,
  ssl,
}: {
  host: string;
  port: number;
  ssl: boolean;
}) {
  const hostAndPort = host + ':' + port;

  return (ssl ? 'https://' : 'http://') + hostAndPort;
}
